var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"sb",attrs:{"width":(_vm.width + "px"),"no-header-close":true,"right":"","shadow":"","no-close-on-esc":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-link',{staticClass:"mr-2 text-info",on:{"click":_vm.onCancel}},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'arrow-left']}}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(_vm.parentTitle),expression:"parentTitle"}]})],1),_vm._v("\n      - "+_vm._s(_vm.displayTitle)+"\n    ")]},proxy:true},{key:"footer",fn:function(){return [(!_vm.isMobile)?_c('div',{staticClass:"d-flex bg-light align-items-center px-3 py-2"},[_c('strong',{staticClass:"mr-auto"}),_vm._v(" "),_c('b-btn',{attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.onCancel}},[_vm._v("\n          "+_vm._s(_vm.$t('eDoc_form_a_close'))+"\n        ")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[(_vm.state)?_c('div',{staticClass:"bg-white px-1 px-lg-3"},[(_vm.userId)?_c('data-layout',{ref:"dt",attrs:{"repo":_vm.repo,"parent-id":_vm.userId,"fields":_vm.fields,"filter-fields":_vm.filterFields,"filter":_vm.filter,"order":_vm.order,"height":_vm.height,"limit":50,"card-collapse":""},on:{"on-filter-submit":_vm.onFilterSubmit,"on-sort":_vm.onSort},scopedSlots:_vm._u([{key:"card(header)",fn:function(ref){
var item = ref.data.item;
return [(item.credit_package)?_c('div',[_vm._v("\n            "+_vm._s(item.credit_package.description)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"small text-muted"},[_c('span',{directives:[{name:"shtml",rawName:"v-shtml",value:(_vm.$dateFormat(item.created_at)),expression:"$dateFormat(item.created_at)"}]})]),_vm._v(" "),_c('div',{staticClass:"small"},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":_vm.statusVariant(item.status)}},[_vm._v("\n              "+_vm._s(item.status)+"\n            ")]),_vm._v(" "),_c('span',[_c('font-awesome-icon',{attrs:{"icon":"euro-sign"}}),_vm._v("\n              "+_vm._s(item.amount_in_euro)+"\n            ")],1),_vm._v(" "),_c('span',{staticClass:"text-muted"},[_vm._v("("+_vm._s(item.gateway_id)+")")])],1)]}},{key:"cell(session_id)",fn:function(ref){
var data = ref.data;
return [(data.item && data.item.session_id)?_c('b-btn',{attrs:{"variant":"info","size":"sm"},on:{"click":function (e) { _vm.copySessionId(e, data.item.session_id) }}},[_vm._v("\n            "+_vm._s(_vm.$t('fCustomer_paid_copyLink'))+"\n          ")]):_vm._e()]}},{key:"cell(club_member)",fn:function(ref){
var item = ref.data.item;
return [(item.club_member)?_c('b-button',{attrs:{"pill":"","disabled":"","variant":"warning","size":"sm"}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":['fas', 'star']}})],1):_c('span')]}},{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [(data.item && data.item.status === 'pending')?_c('b-btn',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.onApprove(data.item)}}},[_vm._v("\n            "+_vm._s(_vm.$t('fCustomer_paid_approveLink'))+"\n          ")]):_vm._e()]}},{key:"cell(market)",fn:function(ref){
var item = ref.data.item;
return [_c('market-flag',{attrs:{"market":item.credit_package.market.name}})]}}],null,false,1043163197)}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }