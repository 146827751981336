<template>
  <div>
    <b-sidebar
      ref="sb"
      v-model="state"
      v-resize="onResize"
      :width="`${width}px`"
      :no-header-close="true"
      right
      shadow
      no-close-on-esc
      no-close-on-backdrop
    >
      <div v-if="state" class="bg-white px-1 px-lg-3">
        <b-tabs
          v-model="activeTab"
          pills
          small
          :card="false"
          nav-wrapper-class="py-2 "
          content-class="px-0"
          @activate-tab="onShowTab"
        >
          <b-tab
            :title="`${$t('fCustomer_reports_receivedTitle')} (${(info.reports && info.reports.received) || 0})`"
            active
          >
            <data-layout
              v-if="userId && activeTab === 0"
              ref="dtReceive"
              :fields="fields"
              :filter="filterReceived"
              :order="order"
              :repo="repo"
              :parent-id="userId"
              :set-item-variant="setItemVariant"
              :height="height"
              :limit="50"
              card-collapse
              @on-sort="onSort"
            >
              <template #card(header)="{ data: { item }}">
                <user-profile-link v-if="item" :user="item.fromUser" />
                <div v-if="item" class="small">
                  <span class="text-muted">{{ $dateFormat(item.created_at) }}</span>
                </div>
                <div
                  v-shtml="item.text"
                  style="overflow:hidden; text-overflow: ellipsis;"
                />
              </template>
              <template #card(actions)="{ data: { item } }">
                <b-dropdown-item-button
                  v-if="item && item.deleted_at"
                  variant="warning"
                  :disabled="busy"
                  @click="onRestore(item.id)"
                >
                  <font-awesome-icon icon="trash-arrow-up" class="mr-1" />
                  {{ $t('pCustomer_a_delete') }}
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  v-else-if="item"
                  variant="danger"
                  :disabled="busy"
                  @click="onDelete(item.id)"
                >
                  <font-awesome-icon icon="trash" class="mr-1" />
                  {{ $t('pCustomer_a_restore') }}
                </b-dropdown-item-button>
              </template>
              <template #cell(id)="{ data }">
                <user-profile-item
                  :user="data.item.fromUser"
                />
              </template>
              <template #cell(text)="{ data: { item } }">
                <span
                  v-shtml="item.text"
                  class="d-block"
                  style="width: 260px;"
                />
              </template>
              <template #cell(created_at)="{ data }">
                <span v-shtml="data.field.formatter(data.item.created_at, data.key, data.item)" />
              </template>
              <template #cell(actions)="{ data }">
                <b-btn
                  v-if="data.item.deleted_at"
                  variant="warning"
                  size="sm"
                  :disabled="busy"
                  @click="onRestore(data.item.id)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'trash-arrow-up']"
                  />
                </b-btn>
                <b-btn
                  v-else
                  variant="danger"
                  size="sm"
                  :disabled="busy"
                  @click="onDelete(data.item.id)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                  />
                </b-btn>
              </template>
              <template #cell(market)="{ data: { item } }">
                <market-flag :market="item.fromUser.market" />
              </template>
            </data-layout>
          </b-tab>
          <b-tab
            :title="`${$t('fCustomer_reports_sentTitle')} (${(info.reports && info.reports.sent) || 0})`"
          >
            <data-layout
              v-if="userId && activeTab === 1"
              ref="dtSent"
              :fields="fields"
              :filter="filterSent"
              :order="order"
              :repo="repo"
              :parent-id="userId"
              :set-item-variant="setItemVariant"
              :height="height"
              :limit="50"
              card-collapse
              @on-sort="onSort"
            >
              <template #card(header)="{ data: { item }}">
                <user-profile-link v-if="item" :user="item.toUser" />
                <div v-if="item" class="small">
                  <span class="text-muted">{{ $dateFormat(item.created_at) }}</span>
                </div>
                <div
                  v-shtml="item.text"
                  style="overflow:hidden; text-overflow: ellipsis;"
                />
              </template>
              <template #card(actions)="{ data: { item } }">
                <b-dropdown-item-button
                  v-if="item && item.deleted_at"
                  variant="warning"
                  :disabled="busy"
                  @click="onRestore(item.id)"
                >
                  <font-awesome-icon icon="trash-arrow-up" class="mr-1" />
                  {{ $t('pCustomer_a_delete') }}
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  v-else-if="item"
                  variant="danger"
                  :disabled="busy"
                  @click="onDelete(item.id)"
                >
                  <font-awesome-icon icon="trash" class="mr-1" />
                  {{ $t('pCustomer_a_restore') }}
                </b-dropdown-item-button>
              </template>
              <template #cell(id)="{ data }">
                <user-profile-item
                  :user="data.item.toUser"
                />
              </template>
              <template #cell(text)="{ data: { item } }">
                <span
                  v-shtml="item.text"
                  class="d-block"
                  style="width: 260px;"
                />
              </template>
              <template #cell(created_at)="{ data }">
                <span v-shtml="data.field.formatter(data.item.created_at, data.key, data.item)" />
              </template>
              <template #cell(actions)="{ data }">
                <b-btn
                  v-if="data.item.deleted_at"
                  variant="warning"
                  size="sm"
                  :disabled="busy"
                  @click="onRestore(data.item.id)"
                >
                  <font-awesome-icon
                    class="text-white"
                    :icon="['fas', 'trash-arrow-up']"
                  />
                </b-btn>
                <b-btn
                  v-else
                  variant="danger"
                  size="sm"
                  :disabled="busy"
                  @click="onDelete(data.item.id)"
                >
                  <font-awesome-icon
                    class="text-white"
                    :icon="['fas', 'trash']"
                  />
                </b-btn>
              </template>
              <template #cell(market)="{ data: { item } }">
                <market-flag :market="item.toUser.market" />
              </template>
            </data-layout>
          </b-tab>
        </b-tabs>
      </div>
      <template #header>
        <b-link class="mr-2 text-info" @click="onCancel">
          <font-awesome-icon v-if="isMobile" :icon="['fas', 'arrow-left']" class="mr-2" />
          <span v-shtml="parentTitle" />
        </b-link>
        - {{ displayTitle }}
      </template>
      <template #footer>
        <div v-if="!isMobile" class="d-flex bg-light align-items-center px-3 py-2">
          <strong class="mr-auto" />
          <b-btn variant="secondary" size="sm" @click="onCancel">
            {{ $t('eDoc_form_a_close') }}
          </b-btn>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'Reports',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    },
    type: {
      type: String,
      default: 'sent' // sent or received
    },
    info: {
      type: Object,
      default: () => ({})
    },
    parentTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      repo: 'customers.reports',
      fields: [
        {
          key: 'created_at',
          label: this.$t('fCustomer_reports_f_date'),
          sortable: true,
          class: 'col-1 text-left',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            return this.$dateFormat(value, 'DD/MM/YYYY<br>HH:mm')
          }
        },
        {
          key: 'id',
          label: this.$t('fCustomer_reports_f_user'),
          sortable: true,
          class: 'col-4',
          thClass: 'text-center',
          cardFullWidth: true
        },
        {
          key: 'market',
          label: this.$t('fCustomer_reports_f_market'),
          class: 'col-1 text-center',
          thClass: 'text-center'
        },
        {
          key: 'text',
          label: this.$t('fCustomer_reports_f_text'),
          sortable: true,
          class: 'col-7 text-left',
          thClass: 'text-center',
          tdClass: 'text-wrap',
          cardFullWidth: true
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thClass: 'text-center'
        }
      ],
      filter: [],
      order: { by: 'created_at', dir: 'desc' },
      state: this.value,
      activeTab: null,
      busy: false,
      height: 500,
      width: 300
    }
  },
  async fetch () {
    if (this.$refs.dtReceive && this.activeTab === 0) {
      await this.$refs.dtReceive.$fetch()
    } else if (this.$refs.dtSent && this.activeTab === 1) {
      await this.$refs.dtSent.$fetch()
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    displayTitle () {
      return `${this.$t('fCustomer_reports_title')} (${this.total})`
    },
    filterReceived () {
      return [
        { column: 'to_user_id', value: this.userId, permanent: true }
      ].concat(this.filter)
    },
    filterSent () {
      return [
        { column: 'from_user_id', value: this.userId, permanent: true }
      ].concat(this.filter)
    },
    total () {
      return this.info?.reports?.tot || 0
    }
  },
  watch: {
    value (n) {
      this.state = n
      if (!n) {
        this.activeTab = null
        this.flushData()
      }
      this.$nextTick(() => {
        this.calcHeight()
        this.calcWidth()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcHeight()
      this.calcWidth()
    })
    if (window) {
      window.addEventListener('resize', this.calcHeight)
    }
  },
  beforeDestroy () {
    if (window) {
      window.removeEventListener('resize', this.calcHeight)
    }
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError'
    }),
    onSort (order) {
      this.order = order
    },
    flushData () {
      this.flush({
        repo: this.repo,
        method: 'index',
        id: this.itemId,
        idx: this.idx
      })
    },
    setItemVariant (item) {
      return item.deleted_at ? 'danger' : ''
    },
    onCancel () {
      this.$emit('input', false)
    },
    onShowTab (activeTab) {
      this.flushData()
      this.$forceUpdate()
      this.$nextTick(() => {
        this.$fetch()
      })
    },
    onDelete (id) {
      this.$confirmDanger({
        title: this.$t('eDoc_list_deleteConfirmTitle'),
        body: this.$t('eDoc_list_deleteConfirmBody', { id }),
        okTitle: this.$t('eDoc_list_deleteConfirmOk'),
        cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
      })
        .then(async (value) => {
          if (value) {
            this.flushError()
            try {
              this.busy = true
              await this.call({
                repo: this.repo,
                method: 'delete',
                id,
                parentId: this.userId
              })
              this.$nextTick(() => {
                this.$fetch()
              })
              this.$emit('update')
            } catch (e) {} finally {
              this.busy = false
            }
          }
        })
    },
    onRestore (id) {
      this.$confirmWarning({
        title: this.$t('eDoc_list_restoreConfirmTitle'),
        body: this.$t('eDoc_list_restoreConfirmBody', { id }),
        okTitle: this.$t('eDoc_list_restoreConfirmOk'),
        cancelTitle: this.$t('eDoc_list_restoreConfirmCancel')
      })
        .then(async (value) => {
          if (value) {
            const payload = {
              deleted_at: null
            }
            await this.call({
              repo: this.repo,
              method: 'update',
              id,
              parentId: this.userId,
              payload
            })
            this.$nextTick(() => {
              this.$fetch()
            })
            this.$emit('update')
          }
        })
    },
    calcHeight () {
      if (!isServer && window) {
        const $sidebar = this.$refs.sb?.$el?.querySelector('.b-sidebar-body')
        const $pager = this.$refs.sb?.$el?.querySelector('.pager')
        const $tabsNav = this.$refs.sb?.$el?.querySelector('.b-sidebar-body .tabs .nav')?.parentElement
        const sidebarHeight = $sidebar?.clientHeight || 0
        const pagerHeight = $pager?.clientHeight || 0
        const tabsNavHeight = $tabsNav?.clientHeight || 0
        // calc.
        this.height = sidebarHeight - pagerHeight - tabsNavHeight
      }
    },
    calcWidth () {
      let width = 0
      if (!isServer) {
        if (this.deviceInfo?.type === 'mobile') {
          width = document.body.offsetWidth
        } else {
          width = Math.round(document.body.offsetWidth * 0.80) - (15 * (this.idx + 1))
        }
      }
      this.width = width
    },
    onResize () {
      this.calcHeight()
      this.calcWidth()
    }
  }
}
</script>

<style scoped>

</style>
