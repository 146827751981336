<template>
  <div>
    <b-sidebar
      ref="sb"
      v-model="state"
      v-resize="onResize"
      :width="`${width}px`"
      :no-header-close="true"
      right
      shadow
      no-close-on-esc
      no-close-on-backdrop
    >
      <div v-if="state" class="bg-white px-1 px-lg-3">
        <data-layout
          v-if="userId"
          ref="dt"
          :repo="repo"
          :parent-id="userId"
          :fields="fields"
          :filter-fields="filterFields"
          :filter="filter"
          :order="order"
          :height="height"
          :limit="50"
          card-collapse
          @on-filter-submit="onFilterSubmit"
          @on-sort="onSort"
        >
          <template #card(header)="{ data: { item }}">
            <div v-if="item.credit_package">
              {{ item.credit_package.description }}
            </div>
            <div class="small text-muted">
              <span v-shtml="$dateFormat(item.created_at)" />
            </div>
            <div class="small">
              <b-badge :variant="statusVariant(item.status)" class="mr-1">
                {{ item.status }}
              </b-badge>
              <span>
                <font-awesome-icon icon="euro-sign" />
                {{ item.amount_in_euro }}
              </span>
              <span class="text-muted">({{ item.gateway_id }})</span>
            </div>
          </template>
          <template #cell(session_id)="{ data }">
            <b-btn
              v-if="data.item && data.item.session_id"
              variant="info"
              size="sm"
              @click="(e) => { copySessionId(e, data.item.session_id) }"
            >
              {{ $t('fCustomer_paid_copyLink') }}
            </b-btn>
          </template>
          <template #cell(club_member)="{ data: { item } }">
            <b-button
              v-if="item.club_member"
              pill
              disabled
              variant="warning"
              size="sm"
            >
              <font-awesome-icon
                :icon="['fas', 'star']"
                class="text-white"
              />
            </b-button>
            <span v-else />
          </template>
          <template #cell(actions)="{ data }">
            <b-btn
              v-if="data.item && data.item.status === 'pending'"
              variant="primary"
              size="sm"
              @click="onApprove(data.item)"
            >
              {{ $t('fCustomer_paid_approveLink') }}
            </b-btn>
          </template>
          <template #cell(market)="{ data: { item } }">
            <market-flag :market="item.credit_package.market.name" />
          </template>
        </data-layout>
      </div>
      <template #header>
        <b-link class="mr-2 text-info" @click="onCancel">
          <font-awesome-icon v-if="isMobile" :icon="['fas', 'arrow-left']" class="mr-2" />
          <span v-shtml="parentTitle" />
        </b-link>
        - {{ displayTitle }}
      </template>
      <template #footer>
        <div v-if="!isMobile" class="d-flex bg-light align-items-center px-3 py-2">
          <strong class="mr-auto" />
          <b-btn variant="secondary" size="sm" @click="onCancel">
            {{ $t('eDoc_form_a_close') }}
          </b-btn>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'Payments',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    },
    parentTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      repo: 'customers.payments',
      fields: [
        {
          key: 'id',
          label: this.$t('fCustomer_paid_f_id'),
          sortable: true
        },
        {
          key: 'created_at',
          label: this.$t('fCustomer_paid_f_date'),
          sortable: true,
          formatter: (value) => {
            return this.$dateFormat(value)
          }
        },
        {
          key: 'status',
          label: this.$t('fCustomer_paid_f_result'),
          sortable: true
        },
        {
          key: 'amount',
          label: this.$t('fCustomer_paid_f_spent'),
          sortable: true
        },
        {
          key: 'gateway_id',
          label: this.$t('fCustomer_paid_f_gate'),
          sortable: true
        },
        {
          key: 'account',
          label: this.$t('fCustomer_paid_f_account'),
          sortable: true,
          formatter: (value, key, item) => {
            return item.payment_account && item.payment_account.name
          }
        },
        {
          key: 'package',
          label: this.$t('fCustomer_paid_f_package'),
          sortable: true,
          formatter: (value, key, item) => {
            return item.credit_package && item.credit_package.description
          }
        },
        {
          key: 'platform',
          label: this.$t('fCustomer_paid_f_platform'),
          sortable: true
        },
        {
          key: 'club_member',
          label: this.$t('fCustomer_paid_f_club'),
          sortable: true,
          class: 'text-center'
        },
        {
          key: 'market',
          label: this.$t('fCustomer_paid_f_market'),
          class: 'col-1 text-center',
          thClass: 'text-center'
        },
        {
          key: 'session_id',
          label: this.$t('fCustomer_paid_f_reference'),
          sortable: false
        },
        {
          key: 'actions',
          label: this.$t('fCustomer_paid_f_actions')
        }
      ],
      filterFields: [
        {
          label: this.$t('fCustomer_paid_s_startDate'),
          key: 'start_date',
          type: 'date',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_paid_s_finishDate'),
          key: 'finish_date',
          type: 'date',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_paid_s_purchaseStatus'),
          key: 'payments.status',
          type: 'select',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_paid_s_gateway'),
          key: 'payments.gateway_id',
          type: 'select',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_paid_s_package'),
          key: 'payments.credit_package_id',
          type: 'select',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_paid_s_platform'),
          key: 'payments.platform',
          type: 'select',
          class: 'col-2'
        },
        {
          label: this.$t('fCustomer_paid_s_club'),
          key: 'club_member',
          type: 'select',
          class: 'col-2'
        }
      ],
      filterState: false,
      filter: [],
      order: { by: 'created_at', dir: 'desc' },
      state: this.value,
      displayTitle: this.$t('fCustomer_paid_title'),
      height: 500,
      width: 300
    }
  },
  async fetch () {
    const query = {}
    if (this.filterFields.length && this.value) {
      if (this.filter && this.filter.length) {
        for (const filter of this.filter) {
          query[`filter[${filter.column}]`] = filter.value
        }
      }
      await this.call({
        repo: this.repo,
        method: 'filterData',
        parentId: this.userId,
        query
      })
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device',
      tableLayout: 'tableLayout'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    filterData () {
      return this.getByName(`${this.repo}/filterData`)
    }
  },
  watch: {
    value (n) {
      this.state = n
      if (this.state) {
        this.$fetch()
      }
      this.$nextTick(() => {
        this.calcHeight()
        this.calcWidth()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcHeight()
      this.calcWidth()
    })
  },
  beforeDestroy () {
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      paymentApprove: 'payment/approve'
    }),
    onSort (order) {
      this.order = order
    },
    copySessionId (event, id) {
      try {
        const el = document.createElement('input')
        el.value = id
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
      } catch (e) {
        // console.error(e)
        // console.error('Cannot copy')
      }
    },
    onFilterSubmit (filter) {
      this.filter = filter
      this.$nextTick(async () => {
        await this.$fetch()
        this.$refs.dt.$fetch()
      })
    },
    toggleFilter () {
      this.filterState = !this.filterState
    },
    async onApprove (item) {
      await this.paymentApprove({ userId: this.userId, id: item.id })
      await this.$refs.dt.$fetch()
      this.$emit('update')
    },
    onCancel () {
      this.$emit('input', false)
    },
    calcHeight () {
      if (!isServer && window) {
        this.$nextTick(() => {
          const $sidebar = this.$refs.sb?.$el?.querySelector('.b-sidebar-body')
          const $pager = this.$refs.sb?.$el?.querySelector('.pager')
          const sidebarHeight = $sidebar?.clientHeight || 0
          const pagerHeight = $pager?.clientHeight || 0
          // calc
          if (sidebarHeight > 0) {
            this.height = sidebarHeight - pagerHeight
          }
        })
      }
    },
    calcWidth () {
      let width = 0
      if (!isServer) {
        if (this.deviceInfo?.type === 'mobile') {
          width = document.body.offsetWidth
        } else {
          width = Math.round(document.body.offsetWidth * 0.80) - (15 * (this.idx + 1))
        }
      }
      this.width = width
    },
    onResize () {
      this.calcHeight()
      this.calcWidth()
    },
    statusVariant (status) {
      switch (status) {
        case 'success':
          return 'success'
        case 'pending':
          return 'warning'
        case 'failed':
          return 'danger'
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
